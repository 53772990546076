<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        width="800"
    >
      <v-card>

        <v-card-title>
          Services
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-0">

          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th>Nom</th>
                <th>Montant minimum</th>
                <th>Montant maximum</th>
                <th>Type</th>
                <th>Valeur</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(service,i) in services" :key="i">

                <td class="pa-2 font-weight-medium">
                  {{ service.name }}
                </td>

                <td>
                  <span class="font-weight-medium gifty--text">
                         {{ CurrencyFormatting(service.pivot.min_amount) }} DZD
                 </span>
                </td>

                <td>
                     <span class="font-weight-medium text-primary">
                            {{ CurrencyFormatting(service.pivot.max_amount) }} DZD
                    </span>
                </td>

                <td>
                  {{ service.pivot.discount_type === "amount" ? "En montant" : "En pourcentage" }}
                </td>

                <td>
                     <span class="font-weight-medium">
                       {{ service.pivot.value }}
                       {{ service.pivot.type === "amount" ? "DZD" : "%" }}
                 </span>
                </td>


              </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      dialog: false,
      services: [],
    }
  },
  methods: {
    open(services) {
      this.dialog = true
      this.services = services
    },
    close() {
      this.dialog = false
    },
  }
}
</script>

