<template>
  <div>
    <v-container fluid>
      <div class="main">
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-space-between mb-3">
              <v-spacer/>

              <v-btn class="rounded-lg"
                     color="gifty"
                     dark
                     depressed
                     to="/cashback/create"
              >
                <v-icon left>mdi-plus</v-icon>
                Ajouter une cashback
              </v-btn>
            </div>

            <v-card class="rounded-lg shadow">
              <v-overlay :value="overlay" absolute color="white" opacity="0.5">
                <v-progress-circular
                    color="primary"
                    indeterminate
                    size="50"
                ></v-progress-circular>
              </v-overlay>

              <v-card-title>
                <div>
                  <v-text-field
                      v-model="keyword"
                      append-icon="mdi-magnify"
                      clearable
                      dense
                      filled
                      hide-details
                      placeholder="Rechercher..."
                      rounded
                      single-line

                  />
                </div>
                <v-spacer/>

                <v-btn color="grey"
                       icon
                       @click="getCashbacks">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>

              </v-card-title>

              <v-divider/>

              <v-card-text v-if="isLoading">
                <v-skeleton-loader type="table"/>
              </v-card-text>

              <div v-else>
                <v-card-text class="pa-0">
                  <v-simple-table v-if="cashbacks.length">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Active/Inactive</th>
                        <th>Nom</th>
                        <th>Date de début</th>
                        <th>Date de fin</th>
                        <th>Services</th>
                        <th>Jours restants</th>
                        <th class="text-center">Tout le monde</th>
                        <th>Créé le</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(cashback ,index) in cashbacks"
                          :key="index"
                      >
                        <td>
                          <v-switch v-model="cashback.is_active"
                                    class="pa-0 ma-0"
                                    color="success"
                                    dense
                                    hide-details
                                    readonly
                                    @click="changeStatus(cashback.id,index)"></v-switch>
                        </td>

                        <td>
                          {{ cashback.name }}
                        </td>

                        <td class="text-no-wrap">
                          <v-icon :style="{marginTop:'-3px'}"
                                  color="gifty"
                                  small>mdi-clock-in
                          </v-icon>
                          {{ cashback.start_date_formated }}
                        </td>

                        <td class="text-no-wrap">
                          <v-icon :style="{marginTop:'-3px'}"
                                  color="gifty"
                                  small>mdi-clock-out
                          </v-icon>
                          {{ cashback.end_date_formated }}
                        </td>

                        <td>
                          <v-chip color="info"
                                  dark
                                  small
                                  @click="$refs.servicesDialog.open(cashback.services)">
                            <v-icon left small>mdi-layers-triple</v-icon>
                            {{ cashback.services.length }}
                          </v-chip>
                        </td>

                        <td>
                          <v-chip :color="cashback.status.color" dark small> {{ cashback.status.value }}</v-chip>
                        </td>

                        <td class="text-center">
                          <v-icon v-if="cashback.assignment_type === 'all'" color="success">
                            mdi-check-circle
                          </v-icon>
                          <v-icon v-else color="success">mdi-circle-outline</v-icon>
                        </td>

                        <td class="text-no-wrap">
                          <v-icon :style="{marginTop:'-3px'}"
                                  color="gifty"
                                  small>mdi-clock-outline
                          </v-icon>
                          {{ cashback.created_at }}
                        </td>

                        <td>

                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon
                                     v-bind="attrs"
                                     v-on="on"
                              >
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list dense>

                              <v-list-item @click="$refs.prolongDialog.open(cashback)">
                                <v-list-item-icon>
                                  <v-icon>mdi-clock-start</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Prolonger</v-list-item-title>
                              </v-list-item>

                              <v-list-item :to="'/cashback/'+cashback.id+'/update'">
                                <v-list-item-icon>
                                  <v-icon>mdi-pencil-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Modifier</v-list-item-title>
                              </v-list-item>

                              <v-list-item @click="remove(cashback.id)">
                                <v-list-item-icon>
                                  <v-icon>mdi-delete-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Supprimer</v-list-item-title>
                              </v-list-item>

                            </v-list>
                          </v-menu>

                        </td>

                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div v-else>
                    <NoResults @reload="getCashbacks"/>
                  </div>
                </v-card-text>

                <v-divider v-if="total > 10"/>

                <v-card-actions v-if="total > 10">

                  <div :style="{width: '120px'}">
                    <v-select v-model="perPage"
                              :items="[10,20,30,50,100]"
                              dense
                              hide-details
                              label="Ligne par page"
                              outlined></v-select>
                  </div>

                  <v-spacer/>

                  <v-pagination v-model="pagination.current"
                                :length="pagination.total"
                                circle
                                total-visible="8"
                                @input="getCashbacks"></v-pagination>

                </v-card-actions>

              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <ServicesDialog ref="servicesDialog"/>
      <ProlongDialog ref="prolongDialog" @refresh="getCashbacks"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import ServicesDialog from "./components/ServicesDialog.vue";
import ProlongDialog from "./components/ProlongDialog.vue";

export default {
  components: {ProlongDialog, NoResults, ServicesDialog},
  data() {
    return {
      keyword: '',
      id: '',
      overlay: false,
      isLoading: false,
      cashbacks: [],

      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },
    };
  },
  methods: {
    getCashbacks() {
      this.isLoading = true
      HTTP.get('/v1/cashbacks?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
          keyword: this.keyword
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

        this.cashbacks = res.data.data.data

        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    remove(id) {
      this.id = id;
      this.$confirm_dialog = true;
    },
    deleteItem() {
      this.overlay = true;
      HTTP.post("/v1/cashbacks/delete", {
        id: this.id,
      }).then(() => {
        this.overlay = false;
        let i = this.cashbacks.findIndex((el) => el.id === this.id);
        this.$delete(this.cashbacks, i);
        this.id = null;
        this.$successMessage = "Cet élément a été supprimé avec succès.";
      }).catch((err) => {
        this.overlay = false;
        console.log(err);
      });
    },
    changeStatus(id, index) {
      this.overlay = true;
      HTTP.post("/v1/cashbacks/change-status", {
        id: id,
      }).then(() => {
        this.overlay = false;
        this.cashbacks[index].is_active = !this.cashbacks[index].is_active
        this.$successMessage = "Cet élément a été modifié avec succès.";
      }).catch((err) => {
        this.overlay = false;
        console.log(err);
      });
    },
  },
  created() {
    this.getCashbacks();
  },
  watch: {
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem();
        this.$is_confirm = false;
      }
    },
    perPage() {
      this.pagination.current = 1
      this.getCashbacks();
    },
    keyword() {
      this.getCashbacks();
    },
  }
};
</script>

<style scoped></style>
